.App {
  text-align: center;
  background: linear-gradient(45deg, #ff99cc, #ff66b2); /* Softer pink gradient */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  position: relative;
  animation: gradient-animation 10s ease infinite; /* Change duration if needed */
}

.App h1 {
  z-index: 1;
  animation: fadeIn 2s ease-in-out forwards, bounce 1s infinite; /* Added bounce animation */
}

.App iframe {
  z-index: 1;
  border: none;
  margin-top: 20px;
  animation: slideUp 1s ease-in-out forwards; /* Slide-in animation */
}

/* Floating shapes */
.floating-shape {
  position: absolute;
  opacity: 0.8;
  animation: float 6s ease-in-out infinite;
}

/* Existing shapes */
.circle1 {
  background-color: rgba(255, 105, 180, 0.7); /* Light pink */
  width: 100px;
  height: 100px;
  top: 5%;
  left: 5%;
}

.circle2 {
  background-color: rgba(255, 182, 193, 0.7); /* Lighter pink */
  width: 80px;
  height: 80px;
  top: 15%;
  left: 10%;
  animation-duration: 5s; /* Different speed for variety */
}

.circle3 {
  background-color: rgba(255, 192, 203, 0.8); /* Light pink with transparency */
  width: 60px;
  height: 60px;
  bottom: 10%;
  left: 15%;
  animation-duration: 7s; /* Different speed for variety */
}

.square1 {
  background-color: rgba(255, 105, 180, 0.7); /* Light pink */
  width: 80px;
  height: 80px;
  top: 40%;
  right: 10%;
  animation-duration: 6s; /* Different speed for variety */
}

.square2 {
  background-color: rgba(255, 182, 193, 0.7); /* Lighter pink */
  width: 100px;
  height: 100px;
  bottom: 15%;
  right: 5%;
  animation-duration: 5s; /* Different speed for variety */
}

.triangle1 {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid rgba(255, 105, 180, 0.7); /* Light pink */
  top: 25%;
  left: 15%;
}

.triangle2 {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 60px solid rgba(255, 182, 193, 0.7); /* Lighter pink */
  bottom: 25%;
  right: 20%;
}

/* New shapes */
.circle4 {
  background-color: rgba(255, 105, 180, 0.7); /* Light pink */
  width: 120px;
  height: 120px;
  top: 50%; /* Varied position */
  left: 30%; /* Moved to the left */
}

.circle5 {
  background-color: rgba(255, 192, 203, 0.8); /* Lighter pink */
  width: 90px;
  height: 90px;
  top: 40%; /* Varied position */
  right: 30%; /* Moved to the right */
}

.square3 {
  background-color: rgba(255, 105, 180, 0.8); /* Light pink */
  width: 70px;
  height: 70px;
  top: 25%; /* Varied position */
  left: 55%; /* Moved to the left */
}

.square4 {
  background-color: rgba(255, 182, 193, 0.7); /* Lighter pink */
  width: 110px;
  height: 110px;
  bottom: 20%; /* Varied position */
  right: 20%; /* Moved to the right */
}

.triangle3 {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 80px solid rgba(255, 105, 180, 0.8); /* Light pink */
  top: 60%; /* Varied position */
  left: 20%; /* Moved to the left */
}

.triangle4 {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid rgba(255, 182, 193, 0.8); /* Lighter pink */
  bottom: 10%; /* Varied position */
  right: 25%; /* Moved to the right */
}

/* Additional shapes */
.circle6 {
  background-color: rgba(255, 105, 180, 0.7);
  width: 100px;
  height: 100px;
  top: 10%;
  right: 5%;
}

.square5 {
  background-color: rgba(255, 182, 193, 0.7);
  width: 60px;
  height: 60px;
  top: 30%;
  left: 40%;
}

.circle7 {
  background-color: rgba(255, 192, 203, 0.8);
  width: 80px;
  height: 80px;
  bottom: 30%;
  left: 40%;
}

.square6 {
  background-color: rgba(255, 105, 180, 0.7);
  width: 70px;
  height: 70px;
  top: 70%;
  right: 10%;
}

.triangle5 {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 60px solid rgba(255, 182, 193, 0.7);
  bottom: 40%;
  left: 15%;
}

.triangle6 {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 60px solid rgba(255, 105, 180, 0.7);
  bottom: 25%;
  right: 15%;
}

.circle8 {
  background-color: rgba(255, 182, 193, 0.8);
  width: 90px;
  height: 90px;
  top: 15%;
  right: 15%;
}

.circle9 {
  background-color: rgba(255, 105, 180, 0.9);
  width: 110px;
  height: 110px;
  top: 75%;
  left: 30%;
}

.square7 {
  background-color: rgba(255, 182, 193, 0.8);
  width: 100px;
  height: 100px;
  top: 80%;
  right: 35%;
}

.triangle7 {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid rgba(255, 192, 203, 0.8);
  top: 50%;
  left: 60%;
}

.triangle8 {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 80px solid rgba(255, 105, 180, 0.8);
  bottom: 45%;
  left: 55%;
}

/* Hover effect for shapes */
.floating-shape:hover {
  transform: scale(1.1); /* Slightly larger on hover */
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

/* Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px); /* Increased floating height */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes gradient-animation {
  0% {
    background-color: #ff7eb3; /* Light pink */
    background-image: linear-gradient(90deg, #ff7eb3, #ff65a3); 
  }
  33% {
    background-color: #ff7eb3; /* Stay on light pink */
    background-image: linear-gradient(90deg, #ff7eb3, #ff6f99); /* Slightly darker gradient */
  }
  66% {
    background-color: #ff6f99; /* Transition to a medium pink */
    background-image: linear-gradient(90deg, #ff6f99, #ff4f99); /* Gradient to darker pink */
  }
  100% {
    background-color: #ff7eb3; /* Back to light pink */
    background-image: linear-gradient(90deg, #ff7eb3, #ff65a3); /* Gradient back to light pink */
  }
}

body {
  animation: gradient-animation 12s ease infinite; /* Adjusted duration for smoother transitions */
}
